


































































































































































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { remoteServiceBaseUrl } from "@/libs/appconst";

import * as appRegion from "@/libs/app_region";

@Component
export default class DeviceList extends Vue {
  pagerHeader: any = {
    title: "设备列表",
    desc: "设备列表",
    breadcrumb: ["设备管理", "设备列表"]
  };
  loading: boolean = false;
  env: any = "";
  filter: any = {
    page: 1,
    size: 10,
    keywords: "",
    startDate: null,
    endDate: null,
    status: "",
    merchantId: null,
    typeId: "",
    isOnline: "",
    agentId: "",
    allocated: "",
    provinceCode: "",
    cityCode: "",
    areaCode: "",
    sortDirection: null,
    sortField: null,
    isActive: null
  };
  dateRange: any = [];
  tableData: any = {
    items: [],
    totalCount: 0
  };
  merchantItems: any[] = [];
  merchantSearchLoading: boolean = false;
  agentItems: any[] = [];
  recycles: any[] = [];
  agentSearchLoading: boolean = false;
  addDeviceDialog: any = {
    show: false,
    form: {
      num: "",
      typeId: ""
    },
    rules: {
      num: [{ required: true, message: "请输入数量", trigger: "blur" }],
      typeId: [{ required: true, message: "请选择类型", trigger: "change" }]
    },
    loading: false
  };
  importDeviceDialog: any = {
    show: false,
    form: {
      file: "",
      typeId: ""
    },
    rules: {
      typeId: [{ required: true, message: "请选择类型", trigger: "change" }],
      file: [
        { required: true, message: "请输入上传excel文件", trigger: "change" }
      ]
    },
    loading: false
  };
  allocationAgentDialog: any = {
    show: false,
    form: {
      id: ""
    },
    rules: {},
    loading: false,
    disable:false
  };
  recycleDialog: any = {
    show: false,
    form: {
      id: ""
    },
    rules: {
      //  typeId1: [{ required: true, message: "请选择类型", trigger: "change" }],
    },
    loading: false,
    disable: false
  };
  allocationAgentDialog1: any = {
    show: false,
    form: {
      id: ""
    },
    rules: {},
    loading: false,
    disable:false
  };
  allocationAgentDialog2: any = {
    show: false,
    form: {
      id: ""
    },
    rules: {},
    loading: false,
    disable:false
  };
  popupDialog:any={ //强制弹出 
    show:false,
    form:{
      slotNo:''
    },
    rules:{
      slotNo:[{ required: true, message: "请选择插槽", trigger: "change" }],
    },
    loading: false,
  }
  deviceId:string=''
  slotCount:any=null
  multipleSelection: any[] = [];
  deviceTypes: any[] = [];

  // 分页开始
  handleSizeChange(val: number) {
    this.filter.size = val;
    this.readerPage();
  }
  handleCurrentChange(val: number) {
    this.filter.page = val;
    this.readerPage();
  }
  async readerPage() {
    this.loading = true;
    try {
      if (this.filter.sortField == "lastReportTime") {
        this.filter.sortDirection = this.filter.sortDirection == 1 ? 0 : 1;
      }
      let res = await this.$ajax.get(
        "/api/services/app/Device/GetPagedDevices",
        {
          params: {
            ...this.filter
          }
        }
      );

      if (res.data.success) {
        this.tableData = res.data.result;
      }
    } catch (error) {
      console.log(error);
    }
    this.loading = false;
  }

  search() {
    this.filter.page = 1;
    // this.filter.keywords = this.keywords;
    this.readerPage();
  }
  // activated() {
  //   this.readerPage();
  // }

  created() {
    if (this.$route.query.id) {
      this.filter.merchantId = Number(this.$route.query.id);
    }
    if(this.$route.query.agentId){
       this.filter.agentId = Number(this.$route.query.agentId);
    }
    if (this.$route.query.keywords) {
      this.filter.keywords = this.$route.query.keywords;
    }
    this.filter.size = this.systemSettings.pageSize;

    let cc = [
      { name: "代理", value: 0 },
      { name: "业务员", value: 1 },
      { name: "商家", value: 2 }
    ];
    this.recycles = cc;
    this.env =
      process.env.NODE_ENV === "production"
        ? process.env.VUE_APP_FLAG === "pro"
          ? "测试服"
          : "正式服"
        : "正式服";
    // console.log(this.env);
    if (
      this.$route.query.isOnline == "1" ||
      this.$route.query.isOnline == "0"
    ) {
      this.filter.isOnline = Boolean(this.$route.query.isOnline);
    }
    this.readerPage();
    this.readerMerchantOptions("");
    this.renderAgentOptions("");
    this.renderDeviceTypes();
    this.provinceOptions = appRegion.getProvinces();
  }
  get statusOptions() {
    return this.getEnum("DeviceStatus");
  }
  async readerMerchantOptions(query: string) {
    this.merchantSearchLoading = true;
    try {
      var res = await this.$ajax.get(
        "/api/services/app/Merchant/GetPagedList",
        {
          params: {
            page: 1,
            size: 10,
            keywords: query,
            categoryId: null,
            agentId: null,
            provinceCode: null,
            cityCode: null,
            areaCode: null
          }
        }
      );

      this.merchantItems = res.data.result.items;
    } catch (error) {}
    this.merchantSearchLoading = false;
  }
  async renderAgentOptions(query: string) {
    this.agentSearchLoading = true;
    try {
      var res = await this.$ajax.get("/api/services/app/Agent/GetPagedList", {
        params: {
          page: 1,
          size: 10,
          keywords: query
        }
      });
      this.agentItems = res.data.result.items;
    } catch (error) {}
    this.agentSearchLoading = false;
  }
  async renderDeviceTypes() {
    try {
      var res = await this.$ajax.get("/api/services/app/DeviceType/GetAll");
      this.deviceTypes = res.data.result;
    } catch (error) {}
  }
  openAddDeviceDialog() {
    this.addDeviceDialog.show = true;
  }
  openImportDeviceDialog() {
    this.importDeviceDialog.show = true;
  }
  saveDevice() {
    (this.$refs.addDeviceForm as any).validate(async (valid: boolean) => {
      if (valid) {
        this.addDeviceDialog.loading = true;
        try {
          var res = await this.$ajax.post(
            `/api/services/app/Device/GenerateDevice?typeId=${this.addDeviceDialog.form.typeId}&num=${this.addDeviceDialog.form.num}`
          );
          if (res.data.success) {
            this.$message.success("创建成功");
            this.readerPage();

            this.addDeviceDialog.show = false;
          }
        } catch (error) {}
        this.addDeviceDialog.loading = false;
      }
    });
  }
  //   分配点击确定
  allocationAgent() {
    if (this.multipleSelection.length == 0)
      return void this.$message.error("请选择设备");
    var deviceIds: any[] = [];
    this.multipleSelection.forEach(item => {
      deviceIds.push(item.id);
    });
    (this.$refs.allocationAgentForm as any).validate(async (valid: boolean) => {
      if (valid) {
        this.allocationAgentDialog.loading = true;
        // 代理提交
        if (this.allocationAgentDialog.form.id) {
          try {
            var res = await this.$ajax.post(
              "/api/services/app/Device/AllocateToAgent",
              {
                deviceIds: deviceIds,
                id: this.allocationAgentDialog.form.id
              }
            );
            if (res.data.success) {
              this.$message.success("分配成功");
              this.readerPage();
              this.allocationAgentDialog.show = false;
            }
          } catch (error) {}
        }
        // 分配给业务员
        if (this.allocationAgentDialog2.form.id) {
          try {
              await this.$ajax.post(
              "/api/services/app/Device/AllocateToBusinessAgent",
              {
                deviceIds: deviceIds,
                id: this.allocationAgentDialog2.form.id
              }
            ).then((res)=>{
              if(res.data.success){
                  this.$message.success("分配成功");
                  this.readerPage();
                  this.allocationAgentDialog2.show = false;
              };
              
            }).catch(res=>{
              console.log(res);
              
            })
          } catch (error) {
            this.$message.warning('请检查网络')
          }
        }
        // 分配给商户提交
        if (this.allocationAgentDialog1.form.id) {
          try {
            var res = await this.$ajax.post(
              "/api/services/app/Device/AllocateToMerchant",
              {
                deviceIds: deviceIds,
                id: this.allocationAgentDialog1.form.id
              }
            );
            if (res.data.success) {
              this.$message.success("分配成功");
              this.readerPage();

              this.allocationAgentDialog1.show = false;
            }
          } catch (error) {}
        }
        if(!this.allocationAgentDialog1.form.id&&!this.allocationAgentDialog2.form.id&&!this.allocationAgentDialog.form.id){
          this.$message.warning("请选择");
        }

        this.allocationAgentDialog.loading = false;
        this.clearFun();
      }
    });
  }
  //   清除模态框内容
  clearFun() {
    this.allocationAgentDialog1.form.id = "";
    this.allocationAgentDialog.form.id = "";
    this.allocationAgentDialog2.form.id = "";
  }
  //   在列表选择设备 否
  handleSelectionChange(val: any[]) {
    console.log(val);
    
    this.multipleSelection = val;
  }
  // 分配代理
  openAllocationAgentDialog() {
    if (this.multipleSelection.length == 0) {
      return void this.$message.error("请选择设备");
    } else {
      this.allocationAgentDialog.show = true;
    }
  }
  // 设备回收
  async recycleMultitermDevice() {
    this.recycleDialog.show = true;
  }
  //   设备回收中 点击确认
  async recycleFun() {
    if (this.multipleSelection.length == 0)
      return void this.$message.error("请选择设备");

    var deviceIds: any[] = [];
    this.multipleSelection.forEach(item => {
      deviceIds.push(item.id);
    });
    (this.$refs.recycleDialogForm as any).validate(async (valid: boolean) => {
      if (valid) {
        this.recycleDialog.loading = true;
        // 代理提交
        console.log(this.recycleDialog.form.id);
        if (this.recycleDialog.form.id || this.recycleDialog.form.id == 0) {
          try {
            var res = await this.$ajax.post(
              "/api/services/app/Device/ManagerRecovery",
              {
                deviceIds: deviceIds,
                recoveryType: this.recycleDialog.form.id
              }
            );
            if (res.data.success) {
              this.$message.success("回收成功");
              this.recycleDialog.show = false;
              this.readerPage();
            }
          } catch (error) {}
        }
        this.recycleDialog.loading = false;
        this.clearFun();
      }
    });
  }

  fileChange(e: any) {
    console.log(e);
    this.importDeviceDialog.form.file = e.target.files[0];
  }
  importDevice() {
    (this.$refs.importDeviceForm as any).validate(async (valid: boolean) => {
      if (valid) {
        this.importDeviceDialog.loading = true;
        try {
          let formData = new FormData();
          formData.append("typeId", this.importDeviceDialog.form.typeId);
          formData.append("file", this.importDeviceDialog.form.file);
          var res = await this.$ajax.post(
            "/api/services/app/Device/Import",
            formData,
            {
              headers: { "Content-Type": "multipart/form-data" }
            }
          );
          if (res.data.success) {
            var data = res.data.result;
            this.$alert(
              `总共${data.total}个设备，导入成功${data.successed}个，导入失败${data.failed}个`,
              "导入完成提示",
              {
                type: "info"
              }
            );
            this.readerPage();

            this.importDeviceDialog.show = false;
          }
        } catch (error) {}
        this.importDeviceDialog.loading = false;
      }
    });
  }
  getDeviceQrcodeUrl(id: string) {
    return `http://211.149.182.192:90/qrcode?text=${encodeURIComponent(
      `https://www.donkeycn.com/appcode/?esno=${id}`
    )}`;
  }
  openDetail(id: number) {
    this.$router.push(`/device/details?id=${id}`);
  }
  async deleteDevice(id: number) {
    var result = await this.$confirm(`确认要删除设备[${id}]吗?`);
    if (result == "confirm") {
      var loading = this.$loading({
        text: "删除中..."
      });
      try {
        var res = await this.$ajax.delete("/api/services/app/Device/Delete", {
          params: {
            id
          }
        });
        if (res.data.success) {
          this.$notify.success("删除成功");
          this.readerPage();
        }
      } catch (error) {}
      loading.close();
    }
  }
  provinceOptions: any[] = [];
  cityOptions: any[] = [];
  countyOptions: any[] = [];
  @Watch("filter.provinceCode")
  provinceCodeChange(provinceCode: string) {
    this.filter.cityCode = "";
    this.filter.areaCode = "";
    this.cityOptions = appRegion.getCitys(provinceCode);
    this.countyOptions = [];
  }
  @Watch("filter.cityCode")
  cityCodeChange(cityCode: string) {
    this.filter.areaCode = "";
    this.countyOptions = appRegion.getCountys(cityCode);
  }
  async sortChange(e: any) {
    console.log(e);
    if (e.order) {
      this.filter.sortField = e.prop;
      if (e.order == "ascending") {
        this.filter.sortDirection = 0;
      } else {
        this.filter.sortDirection = 1;
      }
    } else {
      this.filter.sortDirection = null;
      this.filter.sortField = null;
    }
    this.readerPage();
  }
  async restartDevice(id: string) {
    var result = await this.$confirm(`确认要重启设备${id}吗`);
    if (result == "confirm") {
      var loading = this.$loading({
        text: "请稍后..."
      });
      try {
        var res = await this.$ajax.post(
          "/api/services/app/ChargingBattery/Restart",
          {
            id
          }
        );
        if (res.data.success) {
          this.$notify.success("发送重启命令成功");
          setTimeout(() => {
            this.readerPage();
          }, 1000);
        }
      } catch (error) {}
      loading.close();
    }
  }
  //   切换服务器
  async cutServer(id: string) {
    var result = await this.$confirm(`确认要切换到${this.env}吗`);
    let url =
      this.env === "正式服"
        ? "/api/services/app/ChargingBattery/SwitchToProd"
        : "/api/services/app/ChargingBattery/SwitchToUAT";
    if (result == "confirm") {
      var loading = this.$loading({
        text: "请稍后..."
      });
      try {
        var res = await this.$ajax.post(url, {
          id
        });
        if (res.data.success) {
          this.$notify.success("切换成功");
          setTimeout(() => {
            this.readerPage();
          }, 1000);
        }
      } catch (error) {}
      loading.close();
    }
  }


  // 强制弹出充电宝
  popupDevice(id:string,slotCount:string){
    // 设备Id
    this.slotCount=slotCount
    this.deviceId=id
    // console.log(id,slotCount);
    this.popupDialog.form.slotNo=''
    this.popupDialog.show=true
  }
  popupClick(){
    // console.log(this.popupDialog.form.slotNo);
        (this.$refs.popupDialogForm as any).validate(async (valid: boolean) => {
      if (valid) {
        var loading = this.$loading({
          text: "弹出中..."
        });
        try {
          var res = await this.$ajax.post(
            "/api/services/app/ChargingBattery/Eject",
            {
              slot: this.popupDialog.form.slotNo,
              id: this.deviceId
            }
          );
          if (res.data.success) {
            this.popupDialog.show=false
            this.$message.success("弹出成功");
          }
        } catch (error) {}
          loading.close();
        }
    });
  }
  
}
